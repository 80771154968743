<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายละเอียดผู้ยืนย้าย สายบริหาร"
      >
        <v-row>
          <v-col cols="12" md="8">
            1.ให้สถานศึกษาประชุมพิจารณา
            โดยพิจารณาจากแบบเสนอย้ายของผู้เสนอแต่ละราย
            2.เพื่อพิจารณาเสร็จสิ้นให้สถานศึกษา/งานบุคลากร
            ดำเนินการบันทึกความคิดเห็น ผ่านระบบ Icon สีเหลือ 2.1
            บันทึกความคิดเห็น 2.2 แนบไฟล์ผลการพิจารณา หนังสือนำส่ง วาระการประชุม
            ผลการพิจารณา เป็นไฟล์ PDF 1 ไฟล์
          </v-col>
          <v-col cols="12" md="4"
            ><h4 class="red--text">
              *สายบริหารจะปรากฎเมื่อผู้เสนอย้ายเข้า
            </h4>
            <h2 class="red--text">
              ปิดระบบวันที่ :
              {{ periods.period_college_stop | moment("D MMMM YYYY") }}
            </h2>
          </v-col>
        </v-row>

        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="transference_manage_locations"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">
                <v-checkbox
                  v-model="search_college"
                  :value="item.manage_id_ref"
                ></v-checkbox>
              </td>
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.manage_id_ref }}</td>
              <td class="text-center">{{ item.title_s }}</td>
              <td class="text-center">{{ item.frist_name }}</td>
              <td class="text-center">{{ item.last_name }}</td>
              <td class="text-center">{{ item.college_code_now }}</td>
              <td class="text-center">{{ item.college_name_now }}</td>
              <td class="text-center">{{ item.tlcollege_code }}</td>
              <td class="text-center">
                <v-chip color="">
                  <span style="font-size:16px;"> {{ item.college_name }}</span>
                </v-chip>
              </td>
              <td class="text-center">
                {{ item.time_ss + "/" + item.year_ss }}
              </td>
              <td class="text-center">{{ item.manage_age_app_time }}</td>
              <td class="text-center">
                <v-btn
                  v-if="item.manage_case_move === 'special'"
                  text
                  color="info"
                  :href="'#/user/print_info_manage_d/' + item.manage_id_ref"
                  target="_blank"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
                <v-btn
                  v-else
                  text
                  color="info"
                  :href="'#/user/print_info_manage/' + item.manage_id_ref"
                  target="_blank"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
              </td>

              <td class="text-center">
                <v-chip
                  v-if="item.Mcommittee === 'approp'"
                  color="green"
                  dark
                  :href="'/HRvecfiles/' + item.MdocumentsLinkfile"
                  target="_blank"
                >
                  <v-icon>mdi-printer</v-icon>
                  เห็นควรให้รับย้าย
                </v-chip>
                <v-chip
                  v-else-if="item.Mcommittee === 'inapprop'"
                  color="warning"
                  dark
                  :href="'/HRvecfiles/' + item.MdocumentsLinkfile"
                  target="_blank"
                  ><v-icon>mdi-printer</v-icon>
                  ไม่เห็นควรให้รับย้าย
                </v-chip>
                <v-icon
                  v-else
                  color="yellow"
                  large
                  @click.stop="
                    select_idPosition(
                      item.manage_id_ref,
                      item.manage_location_id_tfl
                    )
                  "
                  >mdi-credit-card-plus</v-icon
                >
              </td>
            </tr>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model positiondialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positiondialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ความคิดเห็นคณะกรรมการบริหารสถานศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.manage_id_ref }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_manages_id_ref.id_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_manages_id_ref.title_s
                        }}{{ transference_manages_id_ref.frist_name }}
                        {{ transference_manages_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_code }}
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </h4>
                      <h4>
                        ย้ายครั้งที่ : {{ periods.period_college_times }} ปี :
                        {{
                          periods.period_college_year
                            | moment("add", "543 years")
                            | moment("YYYY")
                        }}
                      </h4>
                      <h4>
                        รอบการย้าย :
                        {{
                          periods.period_start
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                        -
                        {{
                          periods.period_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h3>
                          เหตุผลในการย้าย :
                        </h3>
                        <h4>
                          {{
                            transference_manage_location_s.manage_reason_detail
                          }}
                        </h4>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex md12>
                        <v-col cols="12" md="12">
                          <v-alert
                            class="mx-auto text-center pa-2 ma-2"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h2>ความคิดเห็น</h2>
                            <h2>
                              <v-radio-group
                                v-model="
                                  transference_manage_location_s.manage_location_status_transfer
                                "
                                row
                                required
                                :rules="[v => !!v || '']"
                                align="center"
                              >
                                <v-radio value="approp">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="primary--text"
                                        >เห็นควรให้รับย้าย</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                                <v-radio value="inapprop">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="warning--text"
                                        >ไม่เห็นควรให้รับย้าย</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </h2>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="12">
                          แนบไฟล์ผลการพิจารณา
                          <v-file-input
                            v-model="documentsLinkfiles"
                            accept=".pdf"
                            name="documentsLinkfiles"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ .pdf"
                            placeholder="เลือกแนบไฟล์ PDF"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <h3 class="red--text">
                    * เมื่อกดบันทึกแล้วไม่สามารถแก้ไขเปลี่ยนแปลงได้
                    กรุณาตรวจสอบให้ให้ถูกต้อง
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positiondialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_manages_id_ref.id_ref }}
                      </h3>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ transference_manages_id_ref.id_card }}
                        <h3>
                          ชื่อ-นามสกุล :
                          {{ transference_manages_id_ref.title_s
                          }}{{ transference_manages_id_ref.frist_name }}
                          {{ transference_manages_id_ref.last_name }}
                        </h3>

                        สถานศึกษาปัจจุบัน :
                        {{ transference_manages_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_manages_id_ref.id_position }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <h3>
                        รหัสวิทยาลัยปลายทาง :
                        {{ man_powerss.college_code || "สับเปลี่ยนตำแหน่ง" }}
                      </h3>
                      <div>
                        วิทยาลัย :
                        {{ man_powerss.college_name || "สับเปลี่ยนตำแหน่ง" }}
                      </div>
                      เลขที่ตำแหน่ง :
                      {{ man_powerss.id_position || "สับเปลี่ยนตำแหน่ง" }}
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="warning" @click.stop="cancelSubmit()" rounded>
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "1",
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      manage_id_ref: [],
      valid: true,
      canceldialog: false,
      positiondialog: false,
      clear_dataDialog: false,
      times_select: "",
      years_select: "",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_manage_locations: [],
      edittransference_manage_location: {},
      search: "",
      search_college: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [
        /*  { text: "อ้างอิง", align: "center", value: "id_ref" }, */
        { text: "เลือก", align: "left", value: "select_item" },
        { text: "#", align: "center", value: "index" },
        { text: "Ref", align: "center", value: "manage_id_ref" },
        { text: "คำนำหน้า", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ส.ปัจจุบัน", align: "center", value: "college_code_now" },
        { text: "ส.ปัจจุบัน", align: "center", value: "college_name_now" },
        { text: "ส.แห่งใหม่", align: "center", value: "tlcollege_code" },

        { text: "ส.แห่งใหม่", align: "center", value: "college_name" },
        { text: "ครั้งที่/ปี", align: "center", value: "time_ys" },
        { text: "อายุงาน", align: "center", value: "manage_age_app_time" },
        { text: "แบบเสนอย้าย", align: "center", value: "printmidRef" },
        {
          text: "ความคิดเห็นกรรมการสถานศึกษา",
          align: "center",
          value: "Mcommittee"
        }
      ],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "tlsequence_n" },
        { text: "รหัสอ้างอิง", align: "center", value: "id_ref" },
        { text: "สาขาวิชา", align: "center", value: "name_branch" },
        { text: "วิทยาลัย", align: "center", value: "college_name" }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_manage_locationstatus: [],
      transference_manage_locations_id_ref: [],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      conditons_transfer_success_del: [],
      man_powers: [],
      userstatus: {},
      updatepositions: {},
      man_powerss: [],
      colleges: [],
      data_select: [],
      provinces: [],
      regions: [],
      transference_manages_id_ref: [],
      period_college_times: [],
      periods: [],
      transference_manage_location_s: [],
      periods: [],
      addreturn_man_power: {},
      man_power_cancel: {},
      documentsLinkfiles: null
    };
  },
  async mounted() {
    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    await this.periodQuery();
  },
  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_college"
      });
      this.periods = result_period.data;
      ;
      if (this.periods.period_college_enable_file != "1") {
        Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.transference_manage_locationQueryAll();
      }
    },

    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          time_s: this.times_select,
          year_s: this.years_select
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
    },

    async search_college_submit() {
      if (
        this.search_college === "" ||
        this.times_select === "" ||
        this.years_select === ""
      ) {
        this.snackbar.icon = "mdi-font-awesome";
        this.snackbar.color = "red";
        this.snackbar.text = "เลือกรายการ กำหนดครั้งที่และปีให้ถูกต้อง";
        this.snackbar.show = true;
      } else {
        this.loading = true;
        let result = await this.$http
          .post("transference_manage_location.php", {
            ApiKey: this.ApiKey,
            time_s: this.times_select,
            year_s: this.years_select,
            college_code: this.search_college
          })
          .finally(() => (this.loading = false));
        this.transference_manage_locations = result.data;
      }
    },

    async search_not_confirm_submit() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          not_confirm: "ok"
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
    },

    async transference_manage_locationQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_manage_location.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.periods.period_college_times,
          manage_year_s: this.periods.period_college_year,
          manage_location_college_code: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
    },

    async select_idPosition(manage_id_ref, manage_location_id_tfl) {
      let result_con = await this.$http.post("transference_manage.php", {
        ApiKey: this.ApiKey,
        manage_id_ref: manage_id_ref
      });
      this.transference_manages_id_ref = result_con.data;

      let result = await this.$http.post("transference_manage_location.php", {
        ApiKey: this.ApiKey,
        manage_location_id_tfl: manage_location_id_tfl
      });
      this.transference_manage_location_s = result.data;

      this.updatepositions = {};
      this.positiondialog = true;
    },

    /// updatepositionSubmit
    async updatepositionSubmit() {
      let result = "";
      let uploaded = null;

      if (this.$refs.updatepositionform.validate()) {
        this.updatepositions.ApiKey = this.ApiKey;
        let userSession = JSON.parse(sessionStorage.getItem("user"));
        this.updatepositions.manage_location_id_tfl = this.transference_manage_location_s.manage_location_id_tfl;
        this.updatepositions.Mcommittee = this.transference_manage_location_s.manage_location_status_transfer;

        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_manage_location_s.manage_id_ref +
            userSession.user_name +
            "collegeAssessment.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updatepositions.MdocumentsLinkfile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }


        let result = await this.$http.post(
          "transference_manage_location.update.php",
          this.updatepositions
        );

        if (result.data.status || upload) {
          Swal.fire({
            icon: "success",
            title: "ปรับปรุงข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_manage_locationQueryAll();
        } else {
          if (!uploaded) {
            Swal.fire({
              icon: "warning",
              title: "ไฟล์ที่แนบไม่ถูกต้อง",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.positiondialog = false;
      }
    },

    getColor() {
      /*  if (calories > 400) return 'red'
        else if (calories > 200) return 'orange'
        else return 'green'  */

      return "green";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "cyan darken-3";
    },
    period_college_years() {
      let yyyy = this.periods.period_college_year;
      return yyyy;
    }
  }
};
</script>
